import {PracticeType} from "../Practice/Practice";
import {Dayjs} from "dayjs";

export interface Dashboard{
    inProgress:number;
    purchaseMade:number;
    closedWithoutSale:number;
    toBeContacted:number;
    expired: number;

    actives:number;
    finished:number;
}

export interface DashboardFilter{
    refundType: PracticeType
    areaField: string
    dataFrom:string
    dataTo:string
    locationCode:string
    insegnaCode:string;
}

export interface DashboardResponse {
    daContattare: number;
    inLavorazione: number;
    acquistoEffettuato: number;
    chiuseSenzaVendita: number;
    scadute: number;
}

export enum DashboardIdSeries{
    IN_PROGRESS= "inProgress",
    TO_BE_CONTACTED= "toBeContacted",
    CLOSE_WITHOUT_SALE= "closedWithoutSale",
    PURCHASE_MADE= "purchaseMade",
    EXPIRED="expired"
}

export interface ExportDashboardDataRequest {
    locationCode?: string;
    refundType?: PracticeType;
    creationDateFrom?: Dayjs;
    creationDateTo?: Dayjs;
    locationGroupCode?: Array<string>;
}
import {PageTitle} from "../../components/Common/PageTitle/PageTitle";
import {Col, Flex, Modal, Row} from "antd";
import "./PracticesDetails.css"
import {LeftColumn} from "../../components/PracticesDetails/LeftColumn/LeftColumn";
import {RightColumn} from "../../components/PracticesDetails/RightColumn/RightColumn";
import {MiddleColumn} from "../../components/PracticesDetails/MiddleColumn/MiddleColumn";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {PracticesHttpService} from "../../services/Http/PracticesHttpService";
import {ClaimStatuses, PracticeDetail} from "../../models/Practice/PracticeDetail";
import {PracticeDiscounts} from "../../components/PracticesDetails/PracticeDiscounts/PracticeDiscounts";
import {EmptyLuxottica} from "../../components/Common/Empty/EmptyLuxottica";
import {SpinLuxottica} from "../../components/Common/SpinLuxottica/SpinLuxottica";
import {InformationService} from "../../services/InformationService";
import {Voucher, VoucherDetail} from "../../models/Info/voucher.model";
import {Utils} from "../../services/Utils";
import {AdminPracticeMenu} from "../../components/AdminPracticeMenu";
import {AuthService} from "../../services/AuthService";
import {NotificationService} from "../../services/NotificationService";
import {PracticeProgressStatus} from "../../models/Practice/Practice";
import {NoteList} from "../../components/PracticesDetails/NoteList";
import {ClaimStatusProvider} from "../../components/PracticesDetails/ClaimStatusProvider";

export enum MiddleColumnStatus {
    INFO = "INFO",
    CHAT = "CHAT",
    NOTE = "NOTE",
}

export function PracticesDetails() {
    const location = useLocation()

    let {id} = useParams();
    const [detail, setDetails] = useState<PracticeDetail | undefined>()

    const [voucher,setVoucher] = useState<Voucher>()
    const [discounts,setDiscounts] = useState<Array<VoucherDetail>>([])

    const [middleColumnStatus, setMiddleColumnStatus] = useState<MiddleColumnStatus>(MiddleColumnStatus.INFO)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        loadData(true);
    }, [location.key])

    const loadData = async (showNoteWarning: boolean = false) => {
        setIsLoading(true);
        try {
            const response = await PracticesHttpService.getPracticeDetail(id);
            const detail = response.data;
            setDetails(detail)

            if (showNoteWarning && [PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE, PracticeProgressStatus.ERRORE_IN_FATTURA, PracticeProgressStatus.PIC_NON_AUTORIZZATA].includes(detail.status)) {
                const response = await PracticesHttpService.getPracticeNotes(id);
                const isPicNonAutorizzata = detail.status === PracticeProgressStatus.PIC_NON_AUTORIZZATA;
                await modal.warning({
                    title: isPicNonAutorizzata ? 'Pratica in pic non autorizzata!' : 'Pratica in rettifica!',
                    width: 500,
                    content: <>
                        Controlla le note qui sotto per avere ulteriori informazioni!
                        <NoteList data={response.data.items}/>
                    </>
                })
            }

            const {data: voucherData} = await InformationService.getVoucherDetails(detail.shopGroup, detail.insuranceProvider.id, detail.agreement)
            setVoucher(voucherData);
            let discountsWithKeys: VoucherDetail[] = Utils.addReactUniqueKey(voucherData.discounts);
            setDiscounts(discountsWithKeys)
        } catch (e) {
            console.log(e);
            NotificationService.getInstance().openNotificationError("Impossibile caricare il dettaglio della pratica")
        } finally {
            setIsLoading(false);
        }
    };

    function onClickChatButton() {
        setMiddleColumnStatus(MiddleColumnStatus.CHAT);
    }

    function onClickTransformToIndirect() {
        if (id == undefined) return;

        PracticesHttpService.transformToIndirect(id).then((response)=>{
            const newClaimId = response.data;
            navigate(`/practices/details/${newClaimId}`, {replace: true})
        })
    }

    function onClickNoteButton() {
        setMiddleColumnStatus(MiddleColumnStatus.NOTE);
    }
    function goToInfo(){
        setMiddleColumnStatus(MiddleColumnStatus.INFO)
    }

    return <>
        <SpinLuxottica loading={isLoading}>
            {contextHolder}
            <ClaimStatusProvider claimStatus={detail? detail.claimStatus : ""} >
            <div className={"practices-details"}>
                <Flex align={"center"} justify={"space-between"}>
                    <PageTitle title={"Pratiche"} historyBack={true} ></PageTitle>
                    <Flex gap={5}>
                        {
                            AuthService.isAdmin() && <AdminPracticeMenu reloadData={loadData} />
                        }
                        {
                            (detail && voucher) &&
                                <PracticeDiscounts voucher={voucher} discounts={discounts}
                                                   insuranceProviderId={detail?.insuranceProvider?.id}></PracticeDiscounts>
                        }
                    </Flex>
                </Flex>
                {
                    detail ?
                        <Row gutter={[16, 8]} className={"row-container"}>
                            <Col span={5}>
                                <div className="col-container">
                                    <LeftColumn agreement={detail.agreement} client={detail?.patient.name ?? ""} email={detail?.patient.email ?? ""}
                                                fiscalCode={detail?.patient.cf ?? ""}
                                                phoneNumber={detail?.patient.phone}
                                                code={detail?.code}
                                                onClickTransformToIndirect={onClickTransformToIndirect}
                                                onClickNoteButton={onClickNoteButton}></LeftColumn>
                                </div>
                            </Col>
                            <Col span={14}>
                                <div className="col-container">
                                    <MiddleColumn status={middleColumnStatus}
                                                  practiceDetail={detail}
                                                  changeVisus={voucher?.visus}
                                                  onClickSavePurchase={loadData}
                                                  onClickConfirmDocs={loadData}
                                                  onClickExit={goToInfo}
                                                  onSaveMessage={loadData}
                                                  reloadDetail={loadData}
                                    />
                                </div>
                            </Col>
                            <Col span={5}>
                                <div className="col-container">
                                    <RightColumn claimStatus={detail?.claimStatus || ClaimStatuses.OPEN} operator={detail?.operator ?? ""} date={detail?.voucherDate ?? ""}
                                                 progress={detail?.progress}
                                                 manage={detail?.agreement}
                                                 onClickChatButton={onClickChatButton}
                                                 onCloseModal={loadData}></RightColumn>
                                </div>
                            </Col>
                        </Row>
                        : <EmptyLuxottica></EmptyLuxottica>
                }
            </div>
            </ClaimStatusProvider>
        </SpinLuxottica>


    </>
}

import {Patient} from "../Patient";
import {InsuranceProvider} from "../InsuranceProvider";
import {PagedResponse} from "../Common/PagedResponse";
import {ClaimStatuses, PracticeProgress} from "./PracticeDetail";

export interface Practice{
    id:number,
    lastModificationDate: string,
    operator: string,
    status: PracticeProgressStatus,
    creationDate: string,
    agreement: PracticeType,

    shop:string,

    patient: Patient,
    insuranceProvider: InsuranceProvider,
    progress:PracticeProgress[]
    chat: string

}

export interface PracticeRow extends Practice{
    key:string
}
export interface PracticeResponse extends PagedResponse<Practice> {}

export enum PracticeProgressStatus {
    DA_CONTATTARE="DA_CONTATTARE",
    CONTATTATO="CONTATTATO",
    PRESO_APPUNTAMENTO ="PRESO_APPUNTAMENTO",
    DOCUMENTAZIONE_INVIATA="DOCUMENTAZIONE_INVIATA",
    ERRORE_IN_DOCUMENTAZIONE ="ERRORE_IN_DOCUMENTAZIONE",
    PIC_AUTORIZZATA ="PIC_AUTORIZZATA",
    PIC_NON_AUTORIZZATA ="PIC_NON_AUTORIZZATA",
    ACQUISTO_EFFETTUATO ="ACQUISTO_EFFETTUATO",
    FATTURA_INVIATA ="FATTURA_INVIATA",
    ERRORE_IN_FATTURA="ERRORE_IN_FATTURA",
    ACQUISTO_NON_EFFETTUATO="ACQUISTO_NON_EFFETTUATO",
    SCADUTA="SCADUTA",
    DOCUMENTAZIONE_DA_INVIARE="DOCUMENTAZIONE_DA_INVIARE",
    FATTURA_DA_INVIARE="FATTURA_DA_INVIARE"
}
export interface PracticeSearchBody{
    claimCode?:string|null,
    patientName?:string|null,
    insuranceProviderId?:number|null,
    status?:Array<PracticeProgressStatus>|null,
    agreement?:PracticeType|null,
    shop?:string|null
    locationGroupCode?:Array<string>|null;
    creationDateRange?:string[]|null[];
    claimStatus?:ClaimStatuses;
}
export enum PracticeType {
    DIRECT = "DIRECT",
    INDIRECT = "INDIRECT",
}

export enum PracticeSearchBodyFilter{
    refundType="agreement",
    convention="insuranceProviderId",
    patientName="patientName",
    locationCode="shop",
    state="status",
    claimStatus="claimStatus",
    creationDateRange="creationDateRange"
}
